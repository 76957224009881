/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Workbox } from "workbox-window";
import { BroadcastChannel } from "broadcast-channel";
import {
    createApplicationUpdateNotification,
    NotificationContexts,
    BroadcastMessageEvent,
    BroadcastMessageTypes,
    NotificationInteraction,
} from "./notifications";

const IS_NATIVE_MOBILE: boolean = JSON.parse(process.env.IS_NATIVE_MOBILE);
const SERVICE_WORKER_CHUNK: string = JSON.parse(process.env.SERVICE_WORKER_CHUNK); // prettier-ignore
const SERVICE_WORKER_HREF = new URL(`${SERVICE_WORKER_CHUNK}.js`,window.__MFE_BASE_URL__.href).href; // prettier-ignore
const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
const FIVE_MINUTES_IN_MILLISECONDS = 1000 * 60 * 5;

/**
 * Allow Workbox (our client-side Service Worker) to initialise under the following scenarios:
 * -------------------------------------------------------------------------------------------
 * 1. We are not in a Native Mobile context (Capacitor has its own caching mechanism).
 * 2. The Browser has Service Worker capability (Safari is late to the Service Worker party).
 *
 * @note Possibly returning `false` forces us to check for `workbox` before calling it.
 */
const workbox =
    !IS_NATIVE_MOBILE && // (1.)
    "serviceWorker" in navigator && // (2.)
    (new Workbox(SERVICE_WORKER_HREF) as false | Workbox);

/**
 * Create a new Broadcast channel for cross tab client-side communication.
 * @note this setup is polyfilled for older browsers and terrible browsers like Safari.
 * @see https://www.npmjs.com/package/broadcast-channel
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
 */
const broadcast = new BroadcastChannel("MFE_SERVICE_WORKER");

/**
 * Broadcast a message to all other browser tabs to reload before then reloading itself.
 */
const reloadAllTabs = () => {
    broadcast.postMessage({ type: BroadcastMessageTypes.MFE_RELOAD_PAGE });
    window.location.reload();
};

/**
 * When a user accepts the "update" notification, trigger the appropriate response contextual to why
 * its existence in the DOM.
 */
const handleAcceptClick: NotificationInteraction = ({ context }) => {
    if (context === NotificationContexts.SERVICE_WORKER) {
        // A built-in Workbox method to tell the waiting Service Worker to "force-install"
        // by posting a "SKIP_WAITING" message.
        // @see https://developers.google.com/web/tools/workbox/guides/migrations/migrate-from-v5?hl=en#new_messageskipwaiting_method
        workbox && workbox.messageSkipWaiting();
    } else if (context === NotificationContexts.STALE_SESSION) {
        reloadAllTabs();
    }
};

/**
 * When a user declines the "update" notification, it is dismissed from the DOM. We tell all other
 * browser tabs to close their version of the "update" notification too.
 */
const handleDeclineClick: NotificationInteraction = () => {
    broadcast.postMessage({
        type: BroadcastMessageTypes.MFE_CLOSE_INSTALL_NOTIFICATION,
    });
};

/**
 * Creates a notification instance that takes "accept" and "decline" interaction params and returns
 * "add" and "remove" hooks for manipulating the notification instance in the DOM.
 */
const applicationUpdateNotification = createApplicationUpdateNotification(handleAcceptClick, handleDeclineClick);

// prettier-ignore
const broadcastMessageHandlers = {
  [BroadcastMessageTypes.MFE_RELOAD_PAGE]: () => window.location.reload(),
  [BroadcastMessageTypes.MFE_CLOSE_INSTALL_NOTIFICATION]: () => applicationUpdateNotification.remove(), // prettier-ignore
  [BroadcastMessageTypes.MFE_ADD_INSTALL_NOTIFICATION]: ({ context }: BroadcastMessageEvent) => applicationUpdateNotification.add({ context }), // prettier-ignore
};

/** Listen for and handle Broadcast messages from other open EP MFE tabs. */
broadcast.addEventListener("message", (event: BroadcastMessageEvent) =>
    broadcastMessageHandlers?.[event?.type]?.(event)
);

workbox &&
    workbox.addEventListener("waiting", () => {
        // A new Service Worker is ready to go... but we need to get user permission to install it.
        broadcast.postMessage({
            type: BroadcastMessageTypes.MFE_ADD_INSTALL_NOTIFICATION,
            context: NotificationContexts.SERVICE_WORKER,
        });
        // Inject an "update" notification into the DOM that will trigger a Service Worker installation
        // (if the user accepts).
        applicationUpdateNotification.add({
            context: NotificationContexts.SERVICE_WORKER,
        });
    });

workbox &&
    workbox.addEventListener("controlling", () => {
        // A new Service Worker is now controlling the page! Let's refresh this tab (that is currently
        // active) and ask all other tabs (in the background) to reload.
        reloadAllTabs();
    });

workbox &&
    workbox.addEventListener("activated", () => {
        // An activated Service Worker no longer needs to ask a user to install it.
        // @note this should be taken care of by the reload sequence (by brute force), but this is here
        // for completeness.
        applicationUpdateNotification.remove();
    });

/**
 * Recursively check for a stale browser session and trigger a notification asking users to "update"
 * (which will simply refresh the page) if such a scenario is encountered.
 */
const incrementallyCheckForStaleSession = () => {
    // If a browser session is more than one day old, we consider that session stale.
    const staleSessionTime = Date.now() + ONE_DAY_IN_MILLISECONDS;
    const checkIsStaleSession = () => Date.now() > staleSessionTime;

    // If we encounter a stale session:
    // --------------------------------
    // 1. Trigger an "update" notification for this and all other active browser sessions.
    // 2. Turn off stale scenarios triggers.
    // 3. Start the whole process again (encase the update notification is closed)
    const handleStaleSession = () => {
        broadcast.postMessage({
            type: BroadcastMessageTypes.MFE_ADD_INSTALL_NOTIFICATION,
            context: NotificationContexts.STALE_SESSION,
        });
        applicationUpdateNotification.add({
            context: NotificationContexts.STALE_SESSION,
        });
        window.clearInterval(intervalId);
        window.removeEventListener("focus", triggerInterval);
        incrementallyCheckForStaleSession();
    };

    // Setup our triggers to check:
    // ----------------------------
    // 1. Every x minutes (remember that the countdown will not run when the browser tab is not in focus).
    // 2. When a browser tab comes back into focus
    const triggerInterval = () => checkIsStaleSession() && handleStaleSession();
    const intervalId = window.setInterval(triggerInterval, FIVE_MINUTES_IN_MILLISECONDS); // prettier-ignore
    window.addEventListener("focus", triggerInterval);
};

/**
 * Register Workbox (our client-side Service Worker) or fall back to a reduced set of capabilities
 * if Workbox is not supported.
 */
const registerWorkbox = () =>
    workbox ? workbox.register().then(incrementallyCheckForStaleSession) : incrementallyCheckForStaleSession();

/**
 * Safari sometimes has alreaded fired the "load" event so we check the ready state to determine if
 * the "load" event is still coming otherwise we call the workbox registration function.
 */
if (document.readyState === "complete") {
    registerWorkbox();
} else {
    window.addEventListener("load", registerWorkbox);
}
